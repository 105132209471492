import { render, staticRenderFns } from "./inCage.vue?vue&type=template&id=0091242f&scoped=true&"
import script from "./inCage.vue?vue&type=script&lang=js&"
export * from "./inCage.vue?vue&type=script&lang=js&"
import style0 from "./inCage.vue?vue&type=style&index=0&id=0091242f&vars=%7B%20bgColor%2C%20FontColor%20%7D&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0091242f",
  null
  
)

export default component.exports