const getColumn = (typeListLength,columnIndex) => {
    if(typeListLength == 3){
        if(columnIndex == 9 || columnIndex == 11 || columnIndex == 13 || columnIndex == 15 || 
        columnIndex == 17 || columnIndex == 19 || columnIndex == 21 || columnIndex == 23){
            return [1, 2];
        }else if(columnIndex == 10 || columnIndex == 12 || columnIndex == 14 || columnIndex == 16 || 
            columnIndex == 18 || columnIndex == 20 || columnIndex == 22 || columnIndex == 24){
            // 这个判断是把第一行的第3列,第4列的值省略,在合并的表格右边直接填原先第5列的值,合并了几列,就省略几列的值
            return [0, 0];
        }
    }
    if(typeListLength == 4){
        if(columnIndex == 10 || columnIndex == 12 || columnIndex == 14 || columnIndex == 16 ||
        columnIndex == 18 || columnIndex == 20 || columnIndex == 22 || columnIndex == 24){
            return [1, 2];
        }else if(columnIndex == 11 || columnIndex == 13 || columnIndex == 15 || columnIndex == 17 ||
            columnIndex == 19 || columnIndex == 21 || columnIndex == 23 || columnIndex == 25){
            // 这个判断是把第一行的第3列,第4列的值省略,在合并的表格右边直接填原先第5列的值,合并了几列,就省略几列的值
            return [0, 0];
        }
    }
    if(typeListLength == 5){
        if(columnIndex == 11 || columnIndex == 13 || columnIndex == 15 || columnIndex == 17 ||
        columnIndex == 19 || columnIndex == 21 || columnIndex == 23 || columnIndex == 25){
            return [1, 2];
        }else if(columnIndex == 12 || columnIndex == 14 || columnIndex == 16 || columnIndex == 18 || 
            columnIndex == 20 || columnIndex == 22 || columnIndex == 24 || columnIndex == 26){
            // 这个判断是把第一行的第3列,第4列的值省略,在合并的表格右边直接填原先第5列的值,合并了几列,就省略几列的值
            return [0, 0];
        }
    }
    if(typeListLength == 6){
        if(columnIndex == 12 || columnIndex == 14 || columnIndex == 24 || columnIndex == 16 ||
        columnIndex == 18 || columnIndex == 20 || columnIndex == 22 || columnIndex == 26){
            return [1, 2];
        }else if(columnIndex == 13 || columnIndex == 25 || columnIndex == 15 || columnIndex == 17 ||
            columnIndex == 19 || columnIndex == 21 || columnIndex == 23 || columnIndex == 27){
            // 这个判断是把第一行的第3列,第4列的值省略,在合并的表格右边直接填原先第5列的值,合并了几列,就省略几列的值
            return [0, 0];
        }
    }
    if(typeListLength == 7){
        if(columnIndex == 13 || columnIndex == 15 || columnIndex == 17 || columnIndex == 19 || 
            columnIndex == 21 || columnIndex == 23 || columnIndex == 25 || columnIndex == 27){
            return [1, 2];
        }else if(columnIndex == 14 || columnIndex == 16 || columnIndex == 18 || columnIndex == 20 || 
            columnIndex == 22 || columnIndex == 24 || columnIndex == 26 || columnIndex == 28){
            // 这个判断是把第一行的第3列,第4列的值省略,在合并的表格右边直接填原先第5列的值,合并了几列,就省略几列的值
            return [0, 0];
        }
    }
    if(typeListLength == 8){
        if(columnIndex == 14 || columnIndex == 16 || columnIndex == 18 || columnIndex == 20 || 
            columnIndex == 22 || columnIndex == 24 || columnIndex == 26 || columnIndex == 28){
            return [1, 2];
        }else if(columnIndex == 15 || columnIndex == 17 || columnIndex == 19 || columnIndex == 21 || 
            columnIndex == 23 || columnIndex == 25 || columnIndex == 27 || columnIndex == 29){
            // 这个判断是把第一行的第3列,第4列的值省略,在合并的表格右边直接填原先第5列的值,合并了几列,就省略几列的值
            return [0, 0];
        }
    }
    if(typeListLength == 9){
        if(columnIndex == 15 || columnIndex == 17 || columnIndex == 19 || columnIndex == 21 || 
            columnIndex == 23 || columnIndex == 25 || columnIndex == 27 || columnIndex == 29){
            return [1, 2];
        }else if(columnIndex == 16 || columnIndex == 18 || columnIndex == 20 || columnIndex == 22 || 
            columnIndex == 24 || columnIndex == 26 || columnIndex == 28 || columnIndex == 30){
            // 这个判断是把第一行的第3列,第4列的值省略,在合并的表格右边直接填原先第5列的值,合并了几列,就省略几列的值
            return [0, 0];
        }
    }
    if(typeListLength == 10){
        if(columnIndex == 16 || columnIndex == 18 || columnIndex == 20 || columnIndex == 22 || 
            columnIndex == 24 || columnIndex == 26 || columnIndex == 28 || columnIndex == 30){
            return [1, 2];
        }else if(columnIndex == 17 || columnIndex == 19 || columnIndex == 21 || columnIndex == 23 || 
            columnIndex == 25 || columnIndex == 27 || columnIndex == 29 || columnIndex == 31){
            // 这个判断是把第一行的第3列,第4列的值省略,在合并的表格右边直接填原先第5列的值,合并了几列,就省略几列的值
            return [0, 0];
        }
    }
    
}

export {
    getColumn
}