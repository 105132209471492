<template>
  <div class="stepContent commonClass">
    <div class="flex relative">
      <h3 class="titHead">合笼情况</h3>
      <el-button size="small" class="export" @click="toAction">操作记录</el-button>
    </div>
    <div class="detail">
      <el-tabs v-model="nowCageId" class="tabsCage" @tab-click="clickTab1">
        <el-tab-pane
            :key="item.id"
            v-for="(item, index) in tabs"
            :label="item.number"
            :name="item.id"
        >
          <el-table
              :data="tableDate" border
              class="tableMember"
              :span-method="objectMethodCageId"
              :row-class-name="getClassNameRow"
              :cell-class-name="getClassNameColumn"
              :row-style="getRowColor"
              @row-dblclick="dbClickColor"
              :header-cell-style="{'background':'rgba(213, 234, 255, 0.5)','color': '#006DFF','fontWeight':'500'}"
          >
            <el-table-column label="笼号" prop="cageId" min-width="100" show-overflow-tooltip>
              <template slot-scope="scope">
                {{ tabs[tabIndex].number }}
                <div class="setMonitoring" @click="toMonitor">
                  <img class="sxt-icon" src="../../../../assets/img/shexiangtou.png" alt="">
                  查看监控
                </div>
              </template>
            </el-table-column>
            <el-table-column label="笼架号" prop="cageNo" show-overflow-tooltip>
              <span>{{ tabs[tabIndex].frameNo }}</span>
            </el-table-column>
            <el-table-column label="耳号" prop="aniQuarResult.overbit" show-overflow-tooltip></el-table-column>
            <el-table-column label="性别">
              <template slot-scope="scope">{{ scope.row.aniQuarResult.gender == 1 ? 'M' : 'F' }}</template>
            </el-table-column>
            <el-table-column label="出生日期">
              <template slot-scope="scope">{{ scope.row.aniQuarResult.birthDate | formatDay }}</template>
            </el-table-column>
            <el-table-column label="基因类型" class-name="addClass" show-overflow-tooltip>
              <el-table-column v-for="(obj,index) in typeList" :key="index"
                               :label="obj.title"
                               min-width="90">
                <template slot-scope="scope">
                  <span v-if="!scope.row.show && scope.row.gene && scope.row.gene[index].type">{{
                      scope.row.gene[index].type
                    }}</span>
                </template>
              </el-table-column>
            </el-table-column>
            <el-table-column label="备注" prop="remark" show-overflow-tooltip>
              <template slot-scope="scope">
                <span>{{ scope.row.remark }}</span>
              </template>
            </el-table-column>

            <el-table-column :label="'怀孕/出生 '+i " class-name="addClass" show-overflow-tooltip
                             v-for="i in 8" :key="i">

              <el-table-column v-for="(obj,index) in babyList" :key="index"
                               :label="obj.title"
                               :index="i"
                               min-width="100">
                <template slot-scope="scope">
                  <!-- 内嵌表格-->
                  <div class="table-nest"
                       :style="{
                    'background':scope.row.babyData[i].bgColor ? scope.row.babyData[i].bgColor : '#fff !important',
                    'color':scope.row.babyData[i].fontColor ? scope.row.babyData[i].fontColor : '#333'
                  }">
                    <div class="table-row" slot="reference">
                      <el-input v-if="i > scope.row.mateObserList.length"
                                v-model="scope.row.babyData[i].remark"
                                :readonly="scope.row.isDel == 1 ? true : false"
                                @change="changeRcRemerk($event,scope.row,i,'add',scope.$index)"
                                placeholder=" " class="smallWidth"></el-input>
                      <el-popover @show="getObserList(scope.row,i)"
                                  placement="bottom-start"
                                  width="400"
                                  trigger="click">
                        <!-- 弹窗内容 start -->
                        <div class="remark-content table-nest">
                          <div class="re-content-list">
                            <div class="re-content-item" v-for="(obser,oIndex) in obserList">
                              <div class="list-row">
                                <span class="list-row-name">{{ nickname }}</span>
                                <span>{{ obser.createTime }}</span>
                              </div>
                              <div class="re-content">{{ obser.content }}</div>
                            </div>
                          </div>
                          <el-input v-model="mateObser.content" type="textarea" :rows="2" placeholder="请输入新的备注内容"
                                    class="smallWidth"></el-input>
                          <div class="remark-btn">
                            <el-button size="small" @click="isRemarkShow = false">取消</el-button>
                            <el-button size="small" type="primary"
                                       @click="changeRcRemerk($event,scope.row,i,'new',scope.$index)">确认
                            </el-button>
                          </div>
                        </div>
                        <!-- 弹窗内容 end -->
                        <template>
                          <el-input slot="reference"
                                    v-show="scope.row.mateObserList.length >= i "
                                    v-model="scope.row.babyData[i].remark" readonly placeholder="备注"
                                    class="smallWidth"></el-input>
                        </template>
                      </el-popover>
                    </div>
                    <!-- @contextmenu.prevent.stop="rightClick"  -->
                    <div class="table-row">
                      <span v-if="scope.row.babyData[i] && scope.row.babyData[i].birthTime"
                            style="width: 68px;display: inline-block;">DOB：</span>
                      <el-date-picker
                          v-if="scope.row.babyData && scope.row.babyData[i]"
                          @change="changeXsRemerk($event,scope.row,i,scope.$index)"
                          v-model="scope.row.babyData[i].birthTime" size="small"
                          type="date"
                          :readonly="scope.row.babyData[i].birthTime != '' || scope.row.isDel==1 ? true : false"
                          prefix-icon="DOB"
                          placeholder="DOB">
                      </el-date-picker>
                    </div>
                    <div class="nest-flex">
                      <div class="nest-line">
                        <el-input v-if="scope.row.babyData[i]" v-model="scope.row.babyData[i].maleNum"
                                  :readonly="scope.row.isDel==1 ? true : false"
                                  @change="changeXsRemerk($event,scope.row,i,scope.$index)"></el-input>
                      </div>
                      <div>
                        <el-input v-if="scope.row.babyData[i]" v-model="scope.row.babyData[i].femaleNum"
                                  :readonly="scope.row.isDel==1 ? true : false"
                                  @change="changeXsRemerk($event,scope.row,i,scope.$index)"></el-input>
                      </div>
                    </div>
                  </div>
                  <!-- 内嵌表格 -->

                  <!-- <span>备注信息</span>
                  <span v-if="!scope.row.show">{{ scope.row }}</span> -->
                </template>

              </el-table-column>
            </el-table-column>
            <el-table-column label="操作" width="150" fixed="right">
              <template slot-scope="scope">
                <!-- isDel:2换笼 -->
                <div class="editColor" v-if="scope.row.isDel != 1">
                  <el-button type="text" @click="exchange(scope.row,scope.$index)">换笼</el-button>
                  <!-- <el-button type="text" @click="dispose(scope.row,scope.$index)">处理</el-button> -->
                  <el-button type="text" @click="takeOut(scope.row,scope.$index)">取出小鼠</el-button>
                  <el-button type="text" @click="deleteA(scope.row,scope.$index,1)">删除</el-button>
                </div>
                <div class="editColor" v-if="scope.row.isDel == 1">
                  <el-button type="text" @click="deleteA(scope.row,scope.$index,0)">撤回删除</el-button>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
      </el-tabs>
    </div>

    <!-- 颜色设置 -->
    <el-dialog width="220px" title="选择颜色" :visible.sync="colorVisible">
      <div class="flex-box" style="margin-bottom: 20px;">
        字体颜色：
        <el-color-picker v-model="fontColor"></el-color-picker>
      </div>
      <div class="flex-box">
        背景颜色：
        <el-color-picker v-model="bgColor"></el-color-picker>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="colorVisible = false">取 消</el-button>
        <el-button type="primary" @click="addColor">确 定</el-button>
      </span>
    </el-dialog>


    <!-- 处理新生小鼠 -->
    <!-- <el-dialog
      title=""
      :close-on-click-modal="false"
      :visible.sync="dialogVisible"
      width="20%"
      :before-close="handleClose">
      <div class="dialog-text">
        <img class="dialog-icon" :src="imgUrl" alt="">
        <div>是否确认将小鼠处理掉？</div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" class="saveBtn" @click="saveDispose">确 定</el-button>
      </span>
    </el-dialog> -->

    <!-- 换笼 -->
    <el-dialog
        title=""
        :close-on-click-modal="false"
        :visible.sync="dialogCage"
        width="40%"
        :before-close="handleClose">
      <div class="dialog-text">
        <el-tabs v-model="exCageId" class="tabsCage" type="card">
          <el-tab-pane
              :key="item.id"
              v-for="item in tabs"
              :label="item.number"
              :name="item.id"
          >
          </el-tab-pane>
        </el-tabs>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogCage = false">取 消</el-button>
        <el-button type="primary" class="saveBtn" @click="saveExchange">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 新生小鼠，记录 -->
    <el-dialog
        title=""
        :close-on-click-modal="false"
        :visible.sync="isAddBaby"
        width="40%"
        :before-close="handleClose">
      <div class="dialog-text">

      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="isAddBaby = false">取 消</el-button>
        <el-button type="primary" class="saveBtn" @click="saveExchange">确 定</el-button>
      </span>
    </el-dialog>

    <!--    操作记录-->
    <cageAction ref="action" :subId="id" :isLabAni="isLabAni"></cageAction>
    <!--   取出小鼠-->
    <removeMice ref="removeCage" :cageId="nowCageId" :subId="id" :isProd="isProd" :isLabAni="isLabAni"></removeMice>
    <!--   实时影像-->
    <realtimeImage ref="image"></realtimeImage>

  </div>

</template>

<script>
import {formatTimer} from '../../../../api/timezone.js'
import {getColumn} from '../../../../api/column.js'

export default {
  name: "inCage",
  components: {
    "cageAction": () => import("./cageAction"),
    "removeMice": () => import("./removeMice"),
    "realtimeImage": () => import("./realtimeImage"),
  },
  props: ['id', 'isLabAni', 'isProd'],
  inject: ['reload'],
  data() {
    return {
      tabs: [],//笼舍列表
      tabIndex: 0,//默认
      tableDate: [],
      typeList: [],
      dialogVisible: false,//处理小鼠，弹框
      dialogCage: false,//换笼，弹框
      imgUrl: require("../../../../assets/img/tips.png"),
      nowCageId: 0,//当前操作，笼舍id
      exCageId: 0,//换笼 新id
      quarResultId: 0,//检疫id

      isAddBaby: false,
      babyList: [
        {title: 'M'}, {title: 'F'}
      ],//新生小鼠
      babyData: [
        {remark: '', birthTime: '', maleNum: '', femaleNum: ''},
        {remark: '', birthTime: '', maleNum: '', femaleNum: ''},
        {remark: '', birthTime: '', maleNum: '', femaleNum: ''},
        {remark: '', birthTime: '', maleNum: '', femaleNum: ''},
        {remark: '', birthTime: '', maleNum: '', femaleNum: ''},
        {remark: '', birthTime: '', maleNum: '', femaleNum: ''},
        {remark: '', birthTime: '', maleNum: '', femaleNum: ''},
        {remark: '', birthTime: '', maleNum: '', femaleNum: ''},
        {remark: '', birthTime: '', maleNum: '', femaleNum: ''},
      ],//新生小鼠数据,固定八条
      mateObser: {
        content: '',//最新输入的
      },
      obserList: [],
      nowDataId: 0,//当前操作的数据id
      colorVisible: false,//颜色选择弹框
      colorType: 0,//操作对象，1父母本，2妊娠记录，3生产记录
      fontColor: '',//字体颜色
      bgColor: '',//背景颜色
      lastIndex: 0,//控制单元格合并
      lastIndex2: 0,
      startIndex: 0,
      nickname: ''
    }
  },
  beforeCreate() {

  },
  mounted() {
    this.$notify({
      title: '温馨提示',
      message: '双击单元格可修改背景颜色和字体颜色噢~',
      duration: 5000
    });
    this.getCageList();//已申请的笼舍编号列表
  },
  methods: {
    //笼舍编号数据
    getCageList() {
      this.$get("/subject/cage/" + this.id, {isLabAni: this.isLabAni}).then(res => {
        if (res.status == 200) {
          if (res.data.length) {
            res.data.forEach((item, index) => {
              item.id = String(item.id)
            })
            this.tabs = res.data;
            this.nowCageId = res.data[this.tabIndex].id;//默认第一个
            this.getTable(res.data[this.tabIndex].id);
          }
        } else {
          this.$message.warn("获取笼舍数据失败")
        }
      })
    },
    //根据对应笼舍id，获取动物数据
    getTable(id) {
      this.$get("/mate/aniCage/" + id, {isLabAni: this.isLabAni}).then(res => {
        res.data.forEach((item, index) => {
          if (!item.orderDetail) {
            item.orderDetail = item.aniQuarResult
          }

          this.typeList = item.orderDetail ? JSON.parse(item.orderDetail.gene) : this.typeList;
          item.gene = item.orderDetail ? JSON.parse(item.orderDetail.gene) : this.typeList
          if (item.gene.length >= 1) {
            // let babyData = this.babyData.splice()
            item.babyData = JSON.parse(JSON.stringify(this.babyData))
          } else {
            item.babyData = JSON.parse(JSON.stringify(this.babyData))
          }

          //回填数据,展示最新一条数据
          if (item.mateObserList && item.mateObserList.length > 0) {
            item.mateObserList.forEach((obser, oIndex) => {
              item.babyData[obser.times].remark = obser.content;
            })
          }
          if (item.mateBirthList && item.mateBirthList.length > 0) {
            item.mateBirthList.forEach((birth, bIndex) => {
              item.babyData[birth.times].birthTime = birth.createTime;
              item.babyData[birth.times].maleNum = birth.maleNum;
              item.babyData[birth.times].femaleNum = birth.femaleNum;
              item.babyData[birth.times].brithId = birth.id;
              item.babyData[birth.times].bgColor = birth.bgColor ? birth.bgColor : '#fff';
              item.babyData[birth.times].fontColor = birth.fontColor ? birth.fontColor : '#333';
            })
          }
        })
        this.tableDate = res.data;
        // console.log(this.tableDate,'data')
        clearTimeout();
      }).catch(() => {
        this.$message.error("获取数据失败")
      })
    },
    //记录列表
    getObserList(row, index) {

      let mateAniCageId = row.mateObserList[0].mateAniCageId;
      let times = index;
      //实验观察记录
      this.$get("/mate/obser/" + mateAniCageId, {times: times}).then(res_obser => {
        this.obserList = [];//重新加载
        res_obser.data.forEach((obser) => {
          obser.createTime = formatTimer(obser.createTime)
        })
        this.obserList = res_obser.data;
      }).catch(() => {
        this.$message.warn("加载失败，请重试！")
      })
    },
    //tab切换
    clickTab1(ev) {
      let id = this.tabs[ev.index].id;
      this.tabIndex = ev.index;
      this.nowCageId = id;
      this.tableDate = [];
      this.getTable(id);//获取表格动物数据
    },

    //换笼
    exchange(row, index) {
      this.nowDataId = row.id;
      this.dialogCage = true;
    },
    //确定，换笼
    saveExchange() {
      if (this.nowCageId == this.exCageId) {
        this.$message.warning('请选择不同的笼舍！');
        return false;
      }
      this.$put("/mate/aniCage/replace/" + this.nowDataId, {newCageId: this.exCageId}).then(res => {
        this.$message.success("换笼成功！");
        this.getTable(this.nowCageId);//刷新数据
        this.dialogCage = false;
      }).catch(() => {
        this.$message.error("换笼失败，请重试！")
      })
    },

    // //处理小鼠
    // dispose(row,index){
    //   this.dialogVisible = true;
    // },
    // //确定，处理
    // saveDispose(){
    //   this.$get("/mate/aniCage/"+id).then(res => {
    //     res.data.forEach((item,index) => {
    //       this.typeList = JSON.parse(item.gene)
    //     })
    //     this.tableDate = res.data;
    //   }).catch(() => {
    //     this.$message.error("获取数据失败")
    //   })
    // },

    //取出小鼠
    takeOut(row, index) {
      this.$refs.removeCage.drawer = true;//打开弹框
    },
    //删除handle=1删除操作，handle = 0就是撤回操作
    deleteA(row, index, type) {
      this.$del("/mate/aniCage/detail/" + row.id, {handle: type}).then(res => {
        this.$message.success("操作成功！");
        this.getTable(this.nowCageId);//刷新数据
      }).catch(() => {
        this.$message.error("操作失败，请重试！")
      })
    },
    //保存一条妊娠记录
    changeRcRemerk(ev, row, remarkIndex, type, index) {
      // console.log(ev,row,remarkIndex,type,index,'changeRcRemerk')
      let content = '';
      //判断,弹框新增 or 表格新增
      if (type === 'new') {
        if (this.mateObser.content == '') {
          return false;
        }
        content = this.mateObser.content;
      } else {
        if (row.babyData[remarkIndex].remark == '') {
          return false;
        }
        content = row.babyData[remarkIndex].remark;
      }
      let params = {
        times: remarkIndex,//babyData下标
        mateAniCageId: row.id,
        content: content
      }
      // 接口添加
      this.$postJson("/mate/obser", params).then(res => {
        //回填
        // row.babyData[remarkIndex].remark = content;
        // this.$set(this.tableDate, index, row);
        this.mateObser.content = '';//初始
        this.getTable(this.nowCageId);
        let that = this;
        setTimeout(function () {
          that.getObserList(row, remarkIndex);
        }, 1000)
      })

    },
    //保存一条生产记录
    changeXsRemerk(ev, row, remarkIndex) {
      let reg = new RegExp('/', 'g')//g代表全部
      let params = {
        times: remarkIndex,//
        mateAniCageId: row.id,
        brithTime: row.babyData[remarkIndex] ? formatTimer(row.babyData[remarkIndex].birthTime).replace(reg, '-') : '',
        femaleNum: row.babyData[remarkIndex].femaleNum,
        maleNum: row.babyData[remarkIndex].maleNum
      }
      if ((row.babyData[remarkIndex].type && row.babyData[remarkIndex].type == 1) || row.babyData[remarkIndex].brithId) {
        //修改
        params.id = row.babyData[remarkIndex].brithId;
        this.$put("/mate/birth", params).then(res => {
        })
      } else {
        //新增
        this.$post("/mate/birth", params).then(res => {
          row.babyData[remarkIndex].type = 1;//修改
          row.babyData[remarkIndex].brithId = res.data;
          this.$set(this.tableDate, index, row);
        })
      }
    },

    //鼠标双击，行
    dbClickColor(row, column, event) {
      // console.log(column.label,column.index,'双击')
      //判断能否显示
      if (column.label == 'M' || column.label == 'F') {
        this.colorType = 3;
        if (row.mateBirthList.length) {
          this.nowDataId = row.babyData[column.index].brithId;
          this.bgColor = row.babyData[column.index].bgColor;
          this.fontColor = row.babyData[column.index].fontColor;
          this.colorVisible = true;
        }
      } else {
        this.colorType = 1;
        this.bgColor = row.bgColor;
        this.fontColor = row.fontColor;
        this.nowDataId = row.id;
        this.colorVisible = true;
      }

    },
    //设置颜色
    addColor() {
      let params = {
        id: this.nowDataId,
        bgColor: this.bgColor,
        fontColor: this.fontColor
      }
      //判断操作对象
      if (this.colorType == 1) {
        this.$putJson("/mate/aniCage", params).then(res => {
          this.$message.success('设置成功！')
        })
        // }else if(this.colorType == 2){
      } else if (this.colorType == 3 || this.colorType == 2) {
        this.$put("/mate/birth", params).then(res => {
          this.$message.success('设置成功！')
        })
      }
      //初始化
      this.nowDataId = 0;
      this.bgColor = '';
      this.fontColor = '';
      // this.reload();//刷新整个页面
      let that = this;
      setTimeout(function () {
        that.getTable(that.nowCageId);//更新数据
      }, 1000)
      this.colorVisible = false;
    },


    //查看监控
    toMonitor() {
      this.$refs.image.drawer = true;
      this.$refs.image.tabs = this.tabs;
      this.$refs.image.cageId = this.nowCageId;
      this.$refs.image.getData();
    },
    //操作记录
    toAction() {
      this.$refs.action.drawer = true;
      this.$refs.action.getList();
    },
    //弹框，关闭
    handleClose() {
      this.dialogCage = false;
      this.dialogVisible = false;
    },
    //table,合并列，行
    objectMethodCageId({row, column, rowIndex, columnIndex}) {
      // console.log(columnIndex,'columnIndex')
      if (columnIndex === 0 || columnIndex === 1) {
        if (rowIndex % this.tableDate.length === 0) {
          return {
            rowspan: this.tableDate.length,
            colspan: 1
          };
        } else {
          return {
            rowspan: 0,
            colspan: 0
          };
        }
      }

      if (rowIndex <= this.tableDate.length) {
        if (this.typeList.length <= 1) {
          if (columnIndex == 7 || columnIndex == 9 || columnIndex == 11 || columnIndex == 13 ||
              columnIndex == 15 || columnIndex == 17 || columnIndex == 19 || columnIndex == 21) {
            return [1, 2];
          } else if (columnIndex == 8 || columnIndex == 10 || columnIndex == 12 || columnIndex == 14 || columnIndex == 16 ||
              columnIndex == 18 || columnIndex == 20 || columnIndex == 22) {
            // 这个判断是把第一行的第3列,第4列的值省略,在合并的表格右边直接填原先第5列的值,
            // 合并了几列,就省略几列的值
            return [0, 0];
          }
        } else if (this.typeList.length == 2) {
          if (columnIndex == 8 || columnIndex == 10 || columnIndex == 12 || columnIndex == 14 || columnIndex == 16 ||
              columnIndex == 18 || columnIndex == 20 || columnIndex == 22) {
            return [1, 2];
          } else if (columnIndex == 9 || columnIndex == 11 || columnIndex == 13 || columnIndex == 15 || columnIndex == 17 ||
              columnIndex == 19 || columnIndex == 21 || columnIndex == 23) {
            // 这个判断是把第一行的第3列,第4列的值省略,在合并的表格右边直接填原先第5列的值,
            // 合并了几列,就省略几列的值
            return [0, 0];
          }
        } else {
          return getColumn(this.typeList.length, columnIndex);
        }

        // if(column.label == '备注'){
        //   this.startIndex = columnIndex+1;
        //   this.lastIndex = 0;
        // }
        // if(column.label == '操作'){
        //   this.endIndex = columnIndex;
        //   this.lastIndex2 = 0;
        // }
        // if(columnIndex < this.endIndex){
        //   let index1 = parseInt(this.startIndex+this.lastIndex);
        //   let index2 = parseInt(this.startIndex+this.lastIndex2)+1;
        //   if(columnIndex == index1){
        //     this.lastIndex = parseInt(this.lastIndex+2);
        //     return [1, 2];
        //   }else if(columnIndex == index2){
        //     this.lastIndex2 = parseInt(this.lastIndex2+2);
        //     return [0, 0];
        //   }
        // }


      }

    },
    //行的classname
    getClassNameRow({row, rowIndex}) {
      if (row.isDel == 1) {
        return 'isDel_text'
      } else {
        return ''
      }
    },
    //列的classname
    getClassNameColumn({row, column, rowIndex, columnIndex}) {
      let geneLength = JSON.parse(row.aniQuarResult.gene).length + 6
      // let length = geneLength
      if (columnIndex >= geneLength && columnIndex < 24) {
        // console.log(columnIndex,'column')
        return 'notMarginPadding'
      } else if (columnIndex == 24) {
        return 'whiteColor'
      }
    },
    //行的sytle
    getRowColor({row, rowIndex}) {
      let styleJson = {
        "color": row.fontColor,
        "background": row.bgColor
      };
      return styleJson
    },
  },

}
</script>

<style vars="{ bgColor, FontColor }" lang="scss" scoped>
@import "../../../../assets/css/exper/experiDetail";

.tabsCage {
  margin-left: 0;
}

.dialog-icon {
  width: 100px;
  height: 100px;
  margin-bottom: 20px;
}

.dialog-text {
  width: 100%;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
}

/* .rowClassColor {
  color: var(--FontColor);
  background: var(--bgColor);
} */

.setMonitoring {
  color: #FFAB57;
  cursor: pointer;
}

.sxt-icon {
  width: 16px;
  height: 16px;
}

/* 内嵌表格 */
.table-nest {
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #333333;
  margin: 0 -12px;
}

.table-nest .table-row {
  width: 210px;
  border-bottom: 1px solid #D5EAFF;
  line-height: 40px;
  padding: 0 8px;
  display: flex;
}

.table-nest .nest-flex {
  display: flex;
  flex-direction: row;

  div {
    /* width:50%; */
    line-height: 40px;
    padding: 0 8px;

    input {
      width: 100%;
    }
  }

}

.stepContent .smallWidth {
  width: auto;
}

.nest-flex .nest-line {
  border-right: 1px solid #D5EAFF;
}

.remark-content {
  padding: 10px;
}

.remark-content .list-row {
  /* height: 30px; */
}

.remark-content .list-row-name {
  font-weight: bold;
  margin-right: 40px;
}

.remark-content .re-content {
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 30px;
  /* margin-bottom: 10px; */
}

.remark-content .remark-btn {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
}

.re-content-list {
  max-height: 200px;
  overflow-y: auto;
  border-bottom: 1px solid rgba(187, 187, 187, 0.3);
}

.re-content-item {
  min-height: 60px;
  border-bottom: 1px solid rgba(187, 187, 187, 0.3);
  padding: 10px;
}
</style>
